import React, { useState, useMemo } from 'react';
import { ColumnDragCard } from './ColumnDragCard';
import { IconButton } from './IconButton';
import './ColumnDragSourceArea.scss';
const SOURCES_PAGE_SIZE = 5; // fraction of 10 for easier counting
// @todo readable status text if not mouse-drag
const SourceBox = ({ column, fieldAssignments, dragState, eventBinder, onSelect, onUnassign }) => {
    const isDragged = dragState ? column === dragState.column : false;
    const isAssigned = useMemo(() => Object.keys(fieldAssignments).some((fieldName) => fieldAssignments[fieldName] === column.index), [fieldAssignments, column]);
    const eventHandlers = useMemo(() => eventBinder(column), [
        eventBinder,
        column
    ]);
    return (React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__box" },
        React.createElement("div", Object.assign({}, (isAssigned ? {} : eventHandlers)),
            React.createElement(ColumnDragCard, { column: column, isAssigned: isAssigned, isShadow: isDragged || isAssigned, isDraggable: !dragState && !isDragged && !isAssigned })),
        React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__boxAction" }, isAssigned ? (React.createElement(IconButton, { key: "clear" // key-prop helps clear focus on click
            , label: "Clear column assignment", small: true, type: "replay", onClick: () => {
                onUnassign(column);
            } })) : (React.createElement(IconButton, { key: "dragSelect" // key-prop helps clear focus on click
            , focusOnly: true, label: dragState && dragState.column === column
                ? 'Unselect column'
                : 'Select column for assignment', small: true, type: "back", onClick: () => {
                onSelect(column);
            } })))));
};
// @todo current page indicator (dots)
export const ColumnDragSourceArea = ({ columns, fieldAssignments, dragState, eventBinder, onSelect, onUnassign }) => {
    const [page, setPage] = useState(0);
    const [pageChanged, setPageChanged] = useState(false);
    const pageCount = Math.ceil(columns.length / SOURCES_PAGE_SIZE);
    const start = page * SOURCES_PAGE_SIZE;
    const pageContents = columns
        .slice(start, start + SOURCES_PAGE_SIZE)
        .map((column, columnIndex) => (React.createElement(SourceBox, { key: columnIndex, column: column, fieldAssignments: fieldAssignments, dragState: dragState, eventBinder: eventBinder, onSelect: onSelect, onUnassign: onUnassign })));
    while (pageContents.length < SOURCES_PAGE_SIZE) {
        pageContents.push(React.createElement("div", { key: pageContents.length, className: "CSVImporter_ColumnDragSourceArea__pageFiller" }));
    }
    return (React.createElement("section", { className: "CSVImporter_ColumnDragSourceArea", "aria-label": "Columns to import" },
        React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__control" },
            React.createElement(IconButton, { label: "Show previous columns", type: "back", disabled: page === 0, onClick: () => {
                    setPage((prev) => Math.max(0, prev - 1));
                    setPageChanged(true);
                } })),
        React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__page" },
            dragState && !dragState.pointerStartInfo ? (React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__pageIndicator", role: "status" },
                "Assigning column ",
                dragState.column.code)) : (
            // show page number if needed (and treat as status role if it has changed)
            // @todo changing role to status does not seem to work
            pageCount > 1 && (React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__pageIndicator", role: pageChanged ? 'status' : 'text' },
                "Page ",
                page + 1,
                " of ",
                pageCount))),
            pageContents),
        React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__control" },
            React.createElement(IconButton, { label: "Show next columns", type: "forward", disabled: page === pageCount - 1, onClick: () => {
                    setPage((prev) => Math.min(pageCount - 1, prev + 1));
                } }))));
};
