import React, { useState, useMemo } from 'react';
import { ImporterFrame } from './ImporterFrame';
import { generatePreviewColumns, generateColumnCode } from './ColumnPreview';
import { useColumnDragState } from './ColumnDragState';
import { ColumnDragObject } from './ColumnDragObject';
import { ColumnDragSourceArea } from './ColumnDragSourceArea';
import { ColumnDragTargetArea } from './ColumnDragTargetArea';
export const ColumnPicker = ({ fields, preview, onAccept, onCancel }) => {
    const columns = useMemo(() => generatePreviewColumns(preview.firstRows, preview.hasHeaders).map((item) => (Object.assign(Object.assign({}, item), { code: generateColumnCode(item.index) }))), [preview]);
    const initialAssignments = useMemo(() => {
        // prep insensitive/fuzzy match stems for known columns
        const columnStems = columns.map((column) => {
            const trimmed = column.header && column.header.trim();
            if (!trimmed) {
                return undefined;
            }
            return trimmed.toLowerCase();
        });
        // pre-assign corresponding fields
        const result = {};
        const assignedColumnIndexes = [];
        fields.forEach((field) => {
            // find by field stem
            const fieldLabelStem = field.label.trim().toLowerCase(); // @todo consider normalizing other whitespace/non-letters
            const matchingColumnIndex = columnStems.findIndex((columnStem, columnIndex) => {
                // no headers or no meaningful stem value
                if (columnStem === undefined) {
                    return false;
                }
                // always check against assigning twice
                if (assignedColumnIndexes[columnIndex]) {
                    return false;
                }
                return columnStem === fieldLabelStem;
            });
            // assign if found
            if (matchingColumnIndex !== -1) {
                assignedColumnIndexes[matchingColumnIndex] = true;
                result[field.name] = matchingColumnIndex;
            }
        });
        return result;
    }, [fields, columns]);
    // track which fields need to show validation warning
    const [fieldTouched, setFieldTouched] = useState({});
    const [validationError, setValidationError] = useState(null);
    const { fieldAssignments, dragState, dragEventBinder, dragHoverHandler, columnSelectHandler, assignHandler, unassignHandler } = useColumnDragState(fields, initialAssignments, (fieldName) => {
        setFieldTouched((prev) => {
            if (prev[fieldName]) {
                return prev;
            }
            const copy = Object.assign({}, prev);
            copy[fieldName] = true;
            return copy;
        });
    });
    return (React.createElement(ImporterFrame, { fileName: preview.file.name, subtitle: "Select Columns", error: validationError, onCancel: onCancel, onNext: () => {
            // mark all fields as touched
            const fullTouchedMap = {};
            fields.some((field) => {
                fullTouchedMap[field.name] = true;
            });
            setFieldTouched(fullTouchedMap);
            // submit if validation succeeds
            const hasUnassignedRequired = fields.some((field) => !field.isOptional && fieldAssignments[field.name] === undefined);
            if (!hasUnassignedRequired) {
                onAccept(Object.assign({}, fieldAssignments));
            }
            else {
                setValidationError('Please assign all required fields');
            }
        } },
        React.createElement(ColumnDragSourceArea, { columns: columns, fieldAssignments: fieldAssignments, dragState: dragState, eventBinder: dragEventBinder, onSelect: columnSelectHandler, onUnassign: unassignHandler }),
        React.createElement(ColumnDragTargetArea, { hasHeaders: preview.hasHeaders, fields: fields, columns: columns, fieldTouched: fieldTouched, fieldAssignments: fieldAssignments, dragState: dragState, eventBinder: dragEventBinder, onHover: dragHoverHandler, onAssign: assignHandler, onUnassign: unassignHandler }),
        React.createElement(ColumnDragObject, { dragState: dragState })));
};
